<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapks.net/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <section id="section-1" class="section-content">
            <Breadcrumb :breadcrumbs="breadcrumbs" />

            <h1 class="section-1-blog-head">
              How to Download GBWhatsApp APK Safely: A Step-by-Step Guide
            </h1>

            <div class="lazy-picture-container writer-banner full-width">
              <picture><img width="auto" height="auto" alt="How to Download GBWhatsApp APK Safely: A Step-by-Step Guide" src="../assets/download-gbwhatsapp-apk-safely.webp"></picture>
            </div>
            <p class="writter-content">
              GBWhatsApp is a popular modified version of WhatsApp that offers advanced features such as hiding online status, custom themes, and enhanced privacy settings. However, since it's not available on the <strong>Google Play Store</strong>, users need to manually download and install the <strong>GBWhatsApp APK</strong>.
            </p>

            <p class="writter-content">
              In this guide, we'll walk you through the <strong>safe and easy</strong> way to download GBWhatsApp without any risks.
            </p>

            <h2 class="intro-title blog">
              Is GBWhatsApp Safe to Use?
            </h2>

            <p class="writter-content">
              GBWhatsApp is a third-party mod, which means: <br>
              ✔ It <strong>is not an official</strong> WhatsApp app. <br>
              ✔ It <strong>is not available</strong> on the Play Store. <br>
              ✔ You must download it from a trusted source to avoid malware. <br>
            </p>
            <div class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
              <div class="wp-block-button">
                <a id="dl" class="wp-block-button__link wp-element-button" rel="dl noopener" href="https://gbwhatsapks.net/download-gbwhatsapp/">GB WhatsApp APK Download</a>
              </div>
            </div>

            <h2 class="intro-title blog">
              How to Download and Install GBWhatsApp APK
            </h2>
            <h3>
              Step 1: Enable Unknown Sources
            </h3>
            <p class="writter-content">
              Since GBWhatsApp is not on the Play Store, you need to <strong>allow app installations from unknown sources</strong>:
            </p>
            <ul>
              <li>Go to <strong>Settings > Security</strong>.</li>
              <li>Find <strong>Install Unknown Apps or Unknown Sources</strong>.</li>
              <li>Enable it for your browser or file manager.</li>
            </ul>

            <h3>
              Step 2: Download the GBWhatsApp APK
            </h3>
            <ul>
              <li>Visit a <strong>trusted website</strong> (such as <a href="https://gbwhatsapks.net/" class="jump-url">gbwhatsapks.net</a>).</li>
              <li>Download the <strong>latest GBWhatsApp APK</strong> version.</li>
            </ul>
            <p class="writter-content">
              <strong>Tip</strong>: Always check for the latest version to avoid bugs and security risks.
            </p>

            <h3>
              Step 3: Install GBWhatsApp
            </h3>
            <ul>
              <li>Locate the <strong>downloaded APK file</strong> in your file manager.</li>
              <li>Tap on it and select <strong>Install</strong>.</li>
              <li>Wait for the installation to complete.</li>
            </ul>

            <h3>
              Step 4: Set Up GBWhatsApp
            </h3>
            <ul>
              <li>Open GBWhatsApp and enter your phone number.</li>
              <li>Verify your number using an OTP.</li>
              <li>Restore your chat backup (if available).</li>
            </ul>

            <h2 class="intro-title blog">
              Features of GBWhatsApp
            </h2>
            <p class="writter-content">
              GBWhatsApp offers features that the official app doesn't: <br>
              Hide <strong>online status</strong>, <strong>blue ticks</strong>, and <strong>typing status</strong>. <br>
              Use <strong>dual WhatsApp</strong> on one phone.<br>
              Customize the <strong>theme</strong>, <strong>fonts</strong>, and <strong>UI</strong>.<br>
              Send <strong>larger files</strong> and more than <strong>30 images at once</strong>.
            </p>

            <h2 class="intro-title blog">
              Final Thoughts
            </h2>
            <p class="writter-content">
              Downloading GBWhatsApp is simple, but <strong>make sure to download it from a reliable source</strong> to avoid security risks. Always update to the latest version to enjoy the best features without issues.
            </p>

            <PostPagination :currentPage="3" />

          </section>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
          |
          <a href="https://gbwhatsapks.net/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" href="https://gbwhatsapks.net/download-gbwhatsapp/">
    Download GBWhatsapp APK
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import PostPagination from '@/components/PostPagination.vue';
import Breadcrumb from '@/components/BreadCrumb.vue';

export default {
  name: 'Home',
  components: {
    PostPagination,
    Breadcrumb,
  },
  data() {
    return {
      pageName: 'home',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  methods: {
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>
